import React from 'react';
import './style.css';
import Bola from '../../assets/imgs/bola-amarela.png';
import 'animate.css';



function Eventos() {
    return (

        <div className="mae-E">
            <h1>EVENTOS</h1>
            <div className="testeF">
                <div className="texto">
                    <p>A ouro 1 já foi, e continua sendo a protagonista de vários eventos nacionais e internacionais como a expocachaça e o Mondial Spirits Bruxelas. Contamos com uma equipe direcionada para eventos além de para intervenções, como por exemplo o nosso BLACK BUSS</p>
                </div>
                <h1 id='bus'>Black <h1 id='amarelo'>Buss</h1></h1>
                <div className="texto">
                    <p>Levando o espírito mineiro para todos os cantos do Brasil, o Black Bus é um ônibus interativo presente em festivais, eventos, shows e degustações</p>
                </div>
            </div>
        </div>



    );


}


export default Eventos;