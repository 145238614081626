import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./style.css";
import GarrafaVelha from '../../assets/imgs/Velha.png';
import GarrafaPrata from '../../assets/imgs/prata.png';
import GarrafaOuro from '../../assets/imgs/OuroMineiro.png';
import GarrafaVidaboa from '../../assets/imgs/VidaBoa.png';
import GarrafaEspecial from '../../assets/imgs/EdicaoEspecial.png';

import MPrata from '../../assets/imgs/MPrata.png';
import MVelha from '../../assets/imgs/MVelha.png';
import MVida from '../../assets/imgs/MVida.png'
import MEspecial from '../../assets/imgs/MEspecial.png'
import MOuro from '../../assets/imgs/MOuro.png'

// import required modules
import { Pagination, Navigation, Scrollbar } from "swiper";

export default function Slider() {

    function MudaInfos(id: any) {
        switch (id) {
            case 1:
                $("#veia").css("color", "red");
                $("#ouro").css("color", "black");
                $("#edicao").css("color", "black");
                $("#vida").css("color", "black");
                $("#prata").css("color", "black");

                $(".sobreP").css("display", "none ");
                $(".sobreVB").hide();
                $(".sobreOm").hide();
                $(".sobreE").hide();
                $(".sobreV").show();

                $(".infoV").show();
                $(".infoP").hide();
                $(".infoVB").hide();
                $(".infoOm").hide();
                $(".infoE").hide();

                $(".VelhaG").show();
                $(".PrataG").hide();
                $(".OuroMineiroG").hide();
                $(".VidaBoaG").hide();
                $(".EspecialG").hide();

                $(".OuroMineiroG").removeClass("animate__backInRight");
                $(".VelhaG").addClass("animate__backInRight");
                $(".PrataG").removeClass("animate__backInRight");
                $(".VidaBoaG").removeClass("animate__backInRight");
                $(".EspecialG").removeClass("animate__backInRight");

                break;
            case 2:
                $("#veia").css("color", "black");
                $("#ouro").css("color", "black");
                $("#edicao").css("color", "black");
                $("#vida").css("color", "black");
                $("#prata").css("color", "red");

                $(".sobreP").show();
                $(".sobreVB").hide();
                $(".sobreOm").hide();
                $(".sobreE").hide();
                $(".sobreV").hide();

                $(".infoV").hide();
                $(".infoP").show();
                $(".infoVB").hide();
                $(".infoOm").hide();
                $(".infoE").hide();

                $(".VelhaG").hide();
                $(".PrataG").show();
                $(".OuroMineiroG").hide();
                $(".VidaBoaG").hide();
                $(".EspecialG").hide();

                $(".OuroMineiroG").removeClass("animate__backInRight");
                $(".VelhaG").removeClass("animate__backInRight");
                $(".PrataG").addClass("animate__backInRight");
                $(".VidaBoaG").removeClass("animate__backInRight");
                $(".EspecialG").removeClass("animate__backInRight");

                break;
            case 3:
                $("#veia").css("color", "black");
                $("#ouro").css("color", "black");
                $("#edicao").css("color", "black");
                $("#vida").css("color", "red");
                $("#prata").css("color", "black");

                $(".sobreP").hide();
                $(".sobreVB").show();
                $(".sobreOm").hide();
                $(".sobreE").hide();
                $(".sobreV").hide();

                $(".infoV").hide();
                $(".infoP").hide();
                $(".infoVB").show();
                $(".infoOm").hide();
                $(".infoE").hide();

                $(".VelhaG").hide();
                $(".PrataG").hide();
                $(".OuroMineiroG").hide();
                $(".VidaBoaG").show();
                $(".EspecialG").hide();

                $(".OuroMineiroG").removeClass("animate__backInRight");
                $(".VelhaG").removeClass("animate__backInRight");
                $(".PrataG").removeClass("animate__backInRight");
                $(".VidaBoaG").addClass("animate__backInRight");
                $(".EspecialG").removeClass("animate__backInRight");

                break;
            case 4:
                $("#veia").css("color", "black");
                $("#ouro").css("color", "black");
                $("#edicao").css("color", "red");
                $("#vida").css("color", "black");
                $("#prata").css("color", "black");

                $(".sobreP").hide();
                $(".sobreVB").hide();
                $(".sobreOm").hide();
                $(".sobreE").show();
                $(".sobreV").hide();

                $(".infoV").hide();
                $(".infoP").hide();
                $(".infoVB").hide();
                $(".infoOm").hide();
                $(".infoE").show();

                $(".VelhaG").hide();
                $(".PrataG").hide();
                $(".OuroMineiroG").hide();
                $(".VidaBoaG").hide();
                $(".EspecialG").show();

                $(".OuroMineiroG").removeClass("animate__backInRight");
                $(".VelhaG").removeClass("animate__backInRight");
                $(".PrataG").removeClass("animate__backInRight");
                $(".VidaBoaG").removeClass("animate__backInRight");
                $(".EspecialG").addClass("animate__backInRight");
                break;
            case 5:
                $("#veia").css("color", "black");
                $("#ouro").css("color", "red");
                $("#edicao").css("color", "black");
                $("#vida").css("color", "black");
                $("#prata").css("color", "black");

                $(".sobreP").hide();
                $(".sobreVB").hide();
                $(".sobreOm").show();
                $(".sobreE").hide();
                $(".sobreV").hide();

                $(".infoV").hide();
                $(".infoP").hide();
                $(".infoVB").hide();
                $(".infoOm").show();
                $(".infoE").hide();

                $(".VelhaG").hide();
                $(".PrataG").hide();
                $(".OuroMineiroG").show();
                $(".VidaBoaG").hide();
                $(".EspecialG").hide();

                $(".OuroMineiroG").addClass("animate__backInRight");
                $(".VelhaG").removeClass("animate__backInRight");
                $(".PrataG").removeClass("animate__backInRight");
                $(".VidaBoaG").removeClass("animate__backInRight");
                $(".EspecialG").removeClass("animate__backInRight");


                break;

            default:
                break;
        }
    }
    return (
        <>
            <div className="SliderM">


                <Swiper
                    slidesPerView={3}
                    spaceBetween={1}
                    slidesPerGroup={1}
                    loop={true}
                    loopFillGroupWithBlank={false}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={false}
                    
                
                    className="mySwiper"
                >
                    <SwiperSlide><img id="fotoo" src={GarrafaEspecial} alt="" /><a onClick={() => MudaInfos(4)}><p id='edicao'>EDIÇÃO ESPECIAL</p></a></SwiperSlide>
                    <SwiperSlide><img id="fotoo" src={GarrafaVidaboa} alt="" /><a onClick={() => MudaInfos(3)}><p id='vida'>VIDA BOA</p></a></SwiperSlide>
                    <SwiperSlide><img id="fotoo" src={GarrafaPrata} alt="" /><a onClick={() => MudaInfos(2)}><p id='prata'>PRATA</p></a></SwiperSlide>
                    <SwiperSlide><img id="fotoo" src={GarrafaVelha} alt="" /><a onClick={() => MudaInfos(1)}><p id='veia'>VELHA</p></a></SwiperSlide>
                    <SwiperSlide><img id="fotoo" src={GarrafaOuro} alt="" /><a onClick={() => MudaInfos(5)}><p id='ouro'>OURO MINEIRO</p></a></SwiperSlide>

                </Swiper>
            </div>
        </>
    );
}
