import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./style.css";
import GarrafaVelha from '../../assets/imgs/Velha.png';
import GarrafaPrata from '../../assets/imgs/prata.png';
import GarrafaOuro from '../../assets/imgs/OuroMineiro.png';
import GarrafaVidaboa from '../../assets/imgs/VidaBoa.png';
import GarrafaEspecial from '../../assets/imgs/EdicaoEspecial.png';

import MPrata from '../../assets/imgs/MPrata.png';
import MVelha from '../../assets/imgs/MVelha.png';
import MVida from '../../assets/imgs/MVida.png'
import MEspecial from '../../assets/imgs/MEspecial.png'
import MOuro from '../../assets/imgs/MOuro.png'

// import required modules
import { Pagination, Navigation, Scrollbar } from "swiper";

export default function Slider() {

    return (
        <>
            <div className="slider">
                <Swiper
                    slidesPerView={3}
                    spaceBetween={-62}
                    slidesPerGroup={1}
                    loop={true}
                    loopFillGroupWithBlank={false}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={false}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide id="garra"><img  src={MOuro} alt="" /></SwiperSlide>
                    <SwiperSlide id="garra"><img  src={MEspecial} alt="" /></SwiperSlide>
                    <SwiperSlide id="garra"><img  src={MVida} alt="" /></SwiperSlide>
                    <SwiperSlide id="garra"><img  src={MVelha} alt="" /></SwiperSlide>
                    <SwiperSlide id="garra"><img  src={MPrata} alt="" /></SwiperSlide>

                </Swiper>
            </div>
        </>
    );
}
