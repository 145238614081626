import React from 'react';
import './style.css';
import 'animate.css';
import Garrafas from '../../assets/imgs/GarrafasGrandes.png';


function Produtos() {
    return (
        <>
            <div className="mae-P">

                <img src={Garrafas} alt="" />
            </div>
            <section className='Velha'>

            </section>
            <section className='Prata'>

            </section>
            <section className='VidaBoa'>

            </section>
            <section className='OuroMineiro'>

            </section>
            <section className='EdicaoEspecial'>

            </section>

        </>

    );


}


export default Produtos;